<template>
  <div>
    <BaseCard
      title="datos del albaran"
      :loading="loading"
      :footer-visible="false"
    >
      <DeliveryNotesForm
        ref="delivery-notes-form"
        v-model="model"
      />
    </BaseCard>
    <DeliveryNotesLinesTable />
    <b-row
      align-v="center"
      align-h="end"
    >
      <b-col cols="auto">
        <b-button
          variant="primary"
          class="text-nowrap rounded-sm px-2"
          :disabled="loading"
          @click="handleSaveButtonClick"
        >
          Guardar
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ApiRestService from '@/api/base-api'
import BaseCard from '@/components/ui/card/BaseCard.vue'
import DeliveryNotesForm from '@/components/delivery-notes/form/DeliveryNotesForm.vue'
import DeliveryNotesLinesTable from '@/components/delivery-notes/table/DeliveryNotesLinesTable.vue'

export default {
  components: { DeliveryNotesLinesTable, DeliveryNotesForm, BaseCard },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapGetters('deliveryNotes', {
      deliveryNotes: 'getDeliveryNotes',
    }),
    model: {
      get() {
        return this.deliveryNotes
      },
      set(value) {
        this.setDeliveryNotes(value)
      },
    },
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    ...mapActions('deliveryNotes', ['loadDeliveryNotes', 'setDeliveryNotes']),
    async fetchData() {
      this.loading = true
      try {
        await this.loadDeliveryNotes(this.$route.params.id)
      } catch (error) {
        console.error(error)
      }
      this.loading = false
    },
    async handleSaveButtonClick() {
      const valid = this.$refs['delivery-notes-form'].validateForm()
      if (!valid) {
        return
      }

      this.loading = true
      const { documentation, removedFiles } = this.$refs['delivery-notes-form'].getDocumentationData()
      try {
        await Promise.all([
          ApiRestService.edit(this.$route.params.id, { ...this.model, documentation }, this.$data.$constants.RESOURCES.RESOURCE_DELIVERY_NOTES),
          ApiRestService.deleteFiles(this.$route.params.id, removedFiles, this.$data.$constants.RESOURCES.RESOURCE_DELIVERY_NOTES),
        ])
        await this.$router.go(-1)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>
</style>
