<template>
  <b-card no-body>
    <b-overlay
      :show="loading"
      variant="white"
      spinner-variant="primary"
      rounded="sm"
    >
      <b-row
        class="p-2"
        align-v="center"
        align-h="between"
      >
        <b-col
          class="font-weight-bold d-flex align-items-center"
          cols="auto"
        >
          <span class="text-uppercase">conceptos e importes</span>
        </b-col>
        <b-col cols="auto">
          <span
            class="text-indigo border-bottom border-indigo font-weight-bold mr-1 cursor-pointer font-small-4"
            @click="createEmptyDeliveryNotesLine"
          >
            + Nuevo concepto / importe
          </span>
        </b-col>
      </b-row>
      <b-table
        hover
        responsive
        :items="deliveryNotesLines"
        :fields="columns"
        :no-local-sorting="true"
      >
        <template #cell(product)="data">
          <ProductSelect
            v-model="data.item.product"
            @option-selected="handleProductSelectChange($event, data.item)"
            @clear="handleProductSelectChange($event, data.item)"
          />
        </template>
        <template #cell(description)="data">
          <b-form-input
            v-model="data.item.description"
            placeholder="Descripción"
            @change="updateDeliveryNotesLine(data.item)"
          />
        </template>
        <template #cell(amount)="data">
          <b-input-group class="input-group-merge">
            <b-form-input
              v-model="data.item.amount"
              placeholder="importe"
              type="number"
              @change="updateDeliveryNotesLine(data.item)"
            />
            <b-input-group-append is-text>
              €
            </b-input-group-append>
          </b-input-group>
        </template>
        <template #cell(units)="data">
          <b-form-input
            v-model="data.item.units"
            placeholder="Unidades"
            type="number"
            @change="updateDeliveryNotesLine(data.item)"
          />
        </template>
        <template #cell(total)="data">
          {{ data.item.total | numberToLocalString }} €
        </template>
        <template #cell(actions)="data">
          <span
            class="d-inline-block px-50 text-danger"
            @click="handleDeleteIconClick(data.item.id)"
          >
            <feather-icon
              v-b-tooltip.hover 
              title="Eliminar"
              icon="TrashIcon"
              size="18"
            />
          </span>
        </template>
      </b-table>
      <b-row
        align-h="around"
        align-v="end"
        no-gutters
        class="p-1 bg-action-blue flex-column"
      >
        <b-col
          cols="auto"
          class="text-right"
        >
          <span class="text-dark-gray font-very-small font-weight-bold"> TOTAL: </span>
          <span class="text-gray-50 justify-content-end d-inline-flex total-value-label">
            {{ (deliveryNotes ? deliveryNotes.total_delivery_note : 0) | numberToLocalString }} €
          </span>
        </b-col>
      </b-row>
    </b-overlay>
  </b-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ProductSelect from '@/components/products/select/ProductSelect.vue'
import ApiRestService from '@/api/base-api'

export default {
  name: 'DeliveryNotesLinesTable',
  components: {
    ProductSelect,
  },
  data() {
    return {
      loading: false,
      columns: [
        {
          label: 'PRODUCTO',
          key: 'product',
          thStyle: { 'min-width': '150px' },
          sortable: false,
        },
        {
          label: 'DESCRIPCIÓN',
          key: 'description',
          sortable: false,
        },
        {
          label: 'IMPORTE',
          key: 'amount',
          sortable: false,
        },
        {
          label: 'UNIDADES',
          key: 'units',
          sortable: false,
          thStyle: { width: '125px' },
        },
        {
          label: 'TOTAL',
          key: 'total',
          sortable: false,
          thStyle: { 'min-width': '125px' },
          thClass: 'text-right px-2',
          tdClass: 'text-right px-2',
        },
        {
          label: '',
          key: 'actions',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '75px' },
        },
      ],
    }
  },
  computed: {
    ...mapGetters('deliveryNotes', {
      deliveryNotes: 'getDeliveryNotes',
      deliveryNotesLines: 'getDeliveryNotesLines',
    }),
  },
  methods: {
    ...mapActions('deliveryNotes', ['loadDeliveryNotes', 'setDeliveryNotesTax']),
    async fetchData() {
      try {
        await this.loadDeliveryNotes(this.deliveryNotes?.id)
      } catch (error) {
        console.error(error)
        this.$toast.error('Ocurrió un error al obtener el listado de lineas del albaran. Por favor inténtelo de nuevo mas tarde.')
      }
    },
    handleProductSelectChange(product, line) {
      const deliveryNotesLine = { ...line }
      deliveryNotesLine.product_id = product?.id || ''

      this.updateDeliveryNotesLine(deliveryNotesLine)
    },
    async updateDeliveryNotesLine(deliveryNotesLine) {
      this.loading = true
      try {
        await ApiRestService.edit(
          deliveryNotesLine?.id,
          { ...deliveryNotesLine, delivery_note_id: this.deliveryNotes.id },
          this.$data.$constants.RESOURCES.RESOURCE_DELIVERY_NOTE_LINES,
        )
        await this.fetchData()
      } finally {
        this.loading = false
      }
    },
    async handleDeleteIconClick(deliveryNotesLineId) {
      const response = await this.$modal.show({
        title: '¿Está seguro?',
        text: 'Se borrará el concepto o importe',
      })

      if (!response.isConfirmed) {
        return
      }

      this.loading = true
      try {
        await ApiRestService.delete(deliveryNotesLineId, this.$data.$constants.RESOURCES.RESOURCE_DELIVERY_NOTE_LINES)
        await this.loadDeliveryNotes(this.deliveryNotes?.id)
      } finally {
        this.loading = false
      }
    },
    async createEmptyDeliveryNotesLine() {
      this.loading = true
      try {
        await ApiRestService.create({ delivery_note_id: this.deliveryNotes?.id }, this.$data.$constants.RESOURCES.RESOURCE_DELIVERY_NOTE_LINES)
        await this.loadDeliveryNotes(this.deliveryNotes?.id)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>
.total-value-label {
  width: 150px;
}
</style>
